<template>
    <BaseSvg>
        <!-- BaseSvg 안에 있는 내용이 <slot> 내용으로 대체됨 -->
        <g>
            <path class="fill_gray" d="M46.6,65.4c0-0.4,0-0.8,0-1.2c0-19.3,0-38.7,0-58c0-0.6,0-1.1,0.2-1.7c0.5-1.6,2-2.6,3.6-2.4
    		c1.6,0.2,2.9,1.5,3,3.1c0,0.4,0,0.8,0,1.2c0,19.2,0,38.4,0,57.7c0,0.4,0,0.8,0,1.4c0.5-0.1,0.7-0.5,0.9-0.7
    		c5.6-5.6,11.2-11.2,16.8-16.8c0.9-0.9,2-1.5,3.3-1.3c2.5,0.4,3.7,3.5,2.1,5.5c-0.2,0.3-0.5,0.6-0.8,0.9
    		C68.2,60.6,60.6,68.2,53,75.8c-2.1,2.1-3.8,2.1-5.9,0c-7.7-7.7-15.3-15.4-23-23c-1.1-1.1-1.6-2.2-1.2-3.7c0.7-2.3,3.4-3.2,5.3-1.7
    		c0.3,0.2,0.6,0.5,0.9,0.8c5.5,5.5,11,11,16.5,16.5c0.3,0.3,0.5,0.6,0.7,0.9C46.4,65.5,46.5,65.4,46.6,65.4z" />
            <path class="fill_gray" d="M50,97.9c-13.5,0-26.9,0-40.4,0c-4.7,0-7.5-2.8-7.5-7.5c0-4.2,0-8.4,0-12.6c0-2.3,1.4-3.8,3.4-3.8
    		c2,0,3.4,1.6,3.4,3.8c0,4.1,0,8.2,0,12.3c0,0.8,0.2,1,1,1c26.7,0,53.4,0,80.1,0c0.8,0,1-0.2,1-1c0-4.1,0-8.2,0-12.3
    		c0-2.3,1.4-3.8,3.4-3.8c2,0,3.4,1.5,3.4,3.8c0,4.3,0,8.7,0,13c0,4.2-2.9,7.1-7.2,7.1C77.1,97.9,63.6,97.9,50,97.9z" />
        </g>
    </BaseSvg>
</template>

<script>
// import BaseSvg from "./BaseSvg.vue";
import BaseSvg from "./BaseSVG.vue";
export default {
    components: { BaseSvg },
};
</script>
