<template>
    <div class="c_content_container contact">

        <!-- 상단배너 START -->
        <section class="c_section c_banner banner_about">
            <div class="c_inner">
                <div class="img_wrapper about">
                    <div class="icon_wrapper">
                        <img src="@/assets/images/banner/icon_about.png" alt="아이콘" class="icon" />
                    </div>
                </div>
            </div>
        </section>
        <!-- 상단배너 END -->

        <section class="c_section ci_content">
            <div class="c_inner">
                <div class="ci_intro">

                    <div class="left" v-slidein:reverse="{ percent: '-10%', threshold: 0.1 }">
                        <div class="image_wrapper">
                            <img :src="require(`@/assets/images/about/ci_signiture-${nowMode}.svg`)" />
                        </div>
                        <div class="btn_wrapper">
                            <a href="/ci.ai" download>
                                <p>AI {{ $t('Download') }}</p>
                                <div class="svg_wrap">
                                    <IconDownload viewBox="0 0 100 100" />
                                </div>
                            </a>
                            <a href="/ci.jpg" download>
                                <p>JPG {{ $t('Download') }}</p>
                                <div class="svg_wrap">
                                    <IconDownload viewBox="0 0 100 100" />
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="right">
                        <div class="title">
                            <h1>{{ $t('Ci Intro Tit 01') }}</h1>
                            <h1 v-textMotion="{ rootMargin: '15% 0px 0%', threshold: 0.5 }" class="point_text"><span
                                    class="textMotion_target">{{ $t('Ci Intro Tit 02') }}</span></h1>
                            <h1>{{ $t('Ci Intro Tit 03') }}</h1>
                        </div>
                        <p v-slidein:reverse="{ percent: '10%' }">
                            {{ $t('Ci Intro Txt') }}
                        </p>
                    </div>

                </div>

                <div class="ci_type">
                    <h1 class="section_title">{{ $t('Ci Form') }}</h1>
                    <div v-slidein:reverse>
                        <div class="image_wrapper">
                            <img :src="require(`@/assets/images/about/ci_symbol-${nowMode}.svg`)" />
                        </div>
                        <h3>{{ $t('Symbol') }}</h3>
                        <p>
                            {{ $t('Symbol Txt') }}
                        </p>
                    </div>
                    <div v-slidein:reverse="{ delay: 160 }">
                        <div class="image_wrapper">
                            <img :src="require(`@/assets/images/about/ci_text-${nowMode}.svg`)" />
                        </div>
                        <h3>{{ $t('Logo') }}</h3>
                        <p>
                            {{ $t('Logo Txt') }}
                        </p>
                    </div>
                </div>

                <div class="ci_color">
                    <h1 class="section_title">{{ $t('Ci Color') }}</h1>
                    <div v-slidein:reverse>
                        <div class="text">
                            <h1 class="blue">DEVER BLUE</h1>
                            <h3>#3E8BCA</h3>
                            <ul>
                                <li>R : 61</li>
                                <li>G : 139</li>
                                <li>B : 202</li>
                            </ul>
                            <ul>
                                <li>C : 75</li>
                                <li>M : 39</li>
                                <li>Y : 7</li>
                                <li>K : 0</li>
                            </ul>
                        </div>
                        <div class="color color1"></div>
                    </div>

                    <div v-slidein:reverse="{ delay: 160 }">
                        <div class="text">
                            <h1 class="violet">DEVER VIOLET</h1>
                            <h3>#A199C9</h3>
                            <ul>
                                <li>R : 161</li>
                                <li>G : 153</li>
                                <li>B : 201</li>
                            </ul>
                            <ul>
                                <li>C : 41</li>
                                <li>M : 44</li>
                                <li>Y : 5</li>
                                <li>K : 0</li>
                            </ul>
                        </div>
                        <div class="color color2"></div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
import IconDownload from '@/components/SVG/IconDownload.vue'
import { computed } from "vue";
import { useStore } from "vuex";
export default {
    components: {
        IconDownload,
    },
    setup() {
        const store = useStore();
        const nowMode = computed(() => store.state.isLightMode);

        return {
            nowMode,
        };
    },
};
</script>

<style scoped src="@/assets/css/about/ci.css"></style>